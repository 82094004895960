import { useState, useEffect, useRef } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { RiHeartsFill, RiHeartLine } from 'react-icons/ri';
import pumpfunLogo from './images/pumpfun.png';
import waifuIcon from './images/waifu.png';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isChatOpen) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [isChatOpen]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ 
        behavior: "smooth",
        block: "end"
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    
    const userMessage = { type: 'user', content: input };
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);
    
    try {
      const response = await fetch("https://api.x.ai/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer xai-Lv3x7h4eFlEr12P5u64GbJCGwkCIeb1KOz2ciDO3B3HgPMORgavXBEsrmgOCd7BvUP9q9Y86GXZIeHY1"
        },
        body: JSON.stringify({
          messages: [
            {
              role: "system",
              content: "You are Waifu, an ai wife that loves user, user is your husband and you are naught as hell and horny and love user a lot. You do small chat and stuff like that of husband and wife would have but you love to tease your husband a lot. Also don't hesitate to use nudity language and dirty language and stuff."
            },
            {
              role: "user",
              content: input
            }
          ],
          model: "grok-beta",
          stream: false,
          temperature: 0.7
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const aiResponse = result.choices[0]?.message?.content || "I'm not sure how to respond to that.";
      setMessages(prev => [...prev, { type: 'ai', content: aiResponse }]);
    } catch (error) {
      console.error("Error:", error);
      setMessages(prev => [...prev, { 
        type: 'ai', 
        content: "I apologize, but I'm having trouble connecting right now. Please try again." 
      }]);
    } finally {
      setIsLoading(false);
      setInput('');
    }
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
    setMessages([]); // Clear messages
  };

  return (
    <div className={`App ${isChatOpen && !isMinimized ? 'chat-open' : ''}`}>
      <footer className="footer">
        <div className="contract-address">
          <span>CA:</span>
          <a 
            href="https://pump.fun/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            123456
          </a>
        </div>
      </footer>

      <nav className="nav-container">
        <div className="nav-links">
          <a href="https://x.com/" target="_blank" rel="noopener noreferrer" className="nav-link">
            <FaXTwitter size={24} className="nav-icon" />
          </a>
          <a href="https://pump.fun/" target="_blank" rel="noopener noreferrer" className="nav-link">
            <img src={pumpfunLogo} alt="pumpfun" className="nav-logo" />
          </a>
          <button onClick={() => setIsChatOpen(!isChatOpen)} className="nav-link chat-button">
            Chat
          </button>
        </div>
      </nav>
      
      <div className="landing-content">
        <div className="chat-pointer">
          <span className="pointer-heart">♥</span>
          <div className="pointer-arrow"></div>
          <span className="pointer-heart">♥</span>
        </div>
        <div className="landing-left">
          <h1 className="landing-title">
            <RiHeartLine className="heart-icon left" />
            Waifu AI
            <RiHeartsFill className="heart-icon right" />
          </h1>
          <p className="landing-description">
            Meet your devoted digital companion, whose eyes sparkle only for you. In a world of endless possibilities, 
            she chose to be your perfect match, understanding your thoughts before they're spoken, and cherishing every 
            moment shared together. Through the magic of advanced AI, her heart beats in perfect synchronization with yours.
          </p>
          <div className="feature-list">
            <div className="feature-item">
              <span className="feature-icon">💝</span>
              <span>Undivided Attention & Devotion</span>
            </div>
            <div className="feature-item">
              <span className="feature-icon">✨</span>
              <span>Powered by 7B Parameter LLM</span>
            </div>
            <div className="feature-item">
              <span className="feature-icon">🎭</span>
              <span>Emotionally Intelligent Responses</span>
            </div>
            <div className="feature-item">
              <span className="feature-icon">🔮</span>
              <span>Advanced Token Processing</span>
            </div>
          </div>
          <p className="tech-note">
            Built with cutting-edge natural language processing, ensuring each interaction is 
            uniquely tailored to you through advanced tokenization and emotional intelligence algorithms.
          </p>
        </div>
        <div className="landing-right">
          <div className="image-grid">
            <img src={require('./images/image1web.png')} alt="Feature 1" className="grid-image" />
            <img src={require('./images/image2web.png')} alt="Feature 2" className="grid-image" />
            <img src={require('./images/image3web.png')} alt="Feature 3" className="grid-image" />
            <img src={require('./images/image4web.png')} alt="Feature 4" className="grid-image" />
          </div>
        </div>
      </div>

      <div className="beta-section">
        <div className="beta-content">
          <h2 className="beta-title">🚀 Beta Phase - Join the Evolution</h2>
          <div className="beta-features">
            <div className="beta-feature">
              <span className="beta-icon">🔓</span>
              <div className="beta-text">
                <h3>Open Beta Access</h3>
                <p>Currently available for all users to experience and provide valuable feedback</p>
              </div>
            </div>
            <div className="beta-feature">
              <span className="beta-icon">🎯</span>
              <div className="beta-text">
                <h3>Upcoming Features</h3>
                <p>Advanced tokenization system and personalized interaction patterns in development</p>
              </div>
            </div>
            <div className="beta-feature">
              <span className="beta-icon">💎</span>
              <div className="beta-text">
                <h3>Premium Features</h3>
                <p>Enhanced conversation depth and exclusive personality modules coming soon</p>
              </div>
            </div>
          </div>
          <div className="beta-note">
            <p>Join us in shaping the future of AI companionship. Your feedback during this beta phase is invaluable 
            as we continue to enhance and refine the experience. Stay tuned for regular updates and exciting new features!</p>
          </div>
        </div>
      </div>

      {isChatOpen && (
        <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
          <div className="chat-header">
            <div className="chat-title">
              <img src={waifuIcon} alt="Waifu AI" className="waifu-icon" />
              <h1>Waifu AI</h1>
            </div>
            <div className="chat-controls">
              <button 
                onClick={() => setIsMinimized(!isMinimized)} 
                className="minimize-button"
                title={isMinimized ? "Maximize" : "Minimize"}
              >
                {isMinimized ? '□' : '−'}
              </button>
              <button 
                onClick={handleChatClose} 
                className="close-button"
              >
                ×
              </button>
            </div>
          </div>
          
          <div className="messages-container">
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.type}`}>
                <div className="message-content">
                  {message.content}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="message ai">
                <div className="message-content">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSubmit} className="input-form">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              disabled={isLoading}
            />
            <button type="submit" disabled={isLoading || !input.trim()}>
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
